import Template from './Popup.vue'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'

let globalOptions = {
    mood: 'normal',
    hasNegative: false,
    decimal: false,
    show: false,
    closable: true,
    blocking: true,
    type: '',
    title: '',
    message: '',
    messageError: '',
    showingError: false,
    list: {},
    level: 3,
    listSec: {},
    listEmpty: i18n.t('popup.default.list_empty'),
    textLogout: '',
    textCancel: i18n.t('popup.default.text_cancel'),
    textSave: i18n.t('popup.default.text_save'),
    textSaveSupervisor: '',
    textSaveStart: '',
    textNext: '',
    textPrev: '',
    classLogout: '',
    classCancel: '',
    classSave: '',
    classSaveStart: '',
    classNext: '',
    classPrev: '',
    inputVal: '',
    productName: '',
    image: false,
    file: false,
    selected: false,
    selectedSec: false,
    selectedEl: undefined,
    selectedSecEl: undefined,
    selectedTab: '',
    selectedSecTab: '',
    disabledMessage: '',
    disNumpad: false,
    dateRange: {},
    dateRangeTwo: {},
    dateStart: '',
    dateEnd: '',
    processData: {},
    processTypes: [],
    currentStep: 0,
    data: [],
    cType: false,
    customClass: '',
    signOptions: {
        penColor: 'rgb(51, 51, 51)',
        backgroundColor: 'rgb(255,255,255)',
        format: 'png',
        watermark: ''
    },
    tempOptions: {
        maxRange: null,
        minRange: null,
        maxNum: 260,
        minNum: -40,
        stepNum: 1,
        numDec: 1,
        stepDec: 1,
        mesure: 'ºC'
    },
    showSaveStart: true,
    routePath: false,
    only_past: false,
    callLogout: function () {},
    callCancel: function () {
        if (this.closable) {
            this.$popup.close()
            store.commit('loginUser/setRefreshLayout', true)
        }
    },
    callSave: function () {},
    callSaveStart: function () {},
    callRemoveAll: function () {},
    callAfterAccess: function (response) {
        var routePath = instance ? instance.routePath : '/home'
        if (routePath) {
            router.push('/blank')
            router.push(routePath)
        }
        if (instance) {
            instance.$popup.close()
        }
    }
}
const defCallLogin = function (self, store) {
    var validation = []
    var employee_id = self.selected
    var employee_pin = self.inputVal

    if (typeof employee_id === 'undefined') {
        validation.push(false)
    }
    if (!Number.isInteger(employee_pin.trim()) && employee_pin.trim().length != 4) {
        validation.push(false)
    }

    if (validation.indexOf(false) == -1) {
        self.showingError = false

        store
            .dispatch('loginUser/doLogin', {
                employee: employee_id,
                password: sha256(employee_pin)
            })
            .then(function (response) {
                if (response) {
                    self.callAfterAccess(response)
                } else {
                    self.showingError = true
                    self.messageError = i18n.t('popup.login.error_access')
                }
            })
    } else {
        self.showingError = true
        self.messageError = i18n.t('popup.login.error_validation')
    }
}

let instance

const Init = function (config = {}) {
    let Tpl = this.extend(Template)

    if (!instance) {
        instance = new Tpl()
        config = {
            ...globalOptions,
            ...config
        }
    } else {
        config = {
            ...globalOptions,
            ...instance.$data,
            ...config
        }
    }

    for (let key in config) {
        if (config.hasOwnProperty(key)) {
            // console.log(key + "   " + config[key])
            instance.$data[key] = config[key]
        }
    }
}

const Open = function (config = {}) {
    if (!instance) {
        Init.call(this, config)

        instance.$data.show = true
        // document.body.style.overflow = 'hidden';
        document.body.appendChild(instance.$mount().$el)
    }
}

const Close = function (config = {}) {
    if (instance) {
        instance.$data.show = false
        document.body.removeChild(instance.$mount().$el)
        instance = undefined
    }
}

const Login = function (config = {}) {
    var defaults = {
        type: 'login',
        title: i18n.t('popup.login.title'),
        listEmpty: i18n.t('popup.login.list_empty'),
        textLogout: i18n.t('popup.login.text_logout'),
        textCancel: i18n.t('popup.login.text_cancel'),
        textSave: i18n.t('popup.login.text_save'),
        disabledMessage: i18n.t('popup.login.select_user'),
        message: i18n.t('popup.login.inactivity_info', {
            s: '30 ' + i18n.tc('assets.timing.minute', 2)
        }),
        disNumpad: true,
        callSave: function (self, store) {
            defCallLogin(self, store)
        },
        callLogout: function (self, store) {
            const employee_id = store.getters['loginUser/getLocalEmployee']
            if (employee_id) {
                store
                    .dispatch('loginUser/doLogout', {
                        employee: employee_id
                    })
                    .then(function (response) {
                        self.$popup.close()
                        router.push('/blank')
                        router.push(self.redirectPath('/home'))
                    })
            } else {
                self.$popup.close()
                router.push('/blank')
                router.push(self.redirectPath('/home'))
            }
        }
    }
    log(typeof config.list, config.list, Object.keys(config.list))
    if (typeof config.list !== 'undefined') {
        var listAux = []
        for (var k in config.list) {
            listAux.push(config.list[k])
        }

        listAux.sort(function (a, b) {
            if (a.name < b.name) return -1
            return 1
        })

        config.list = listAux
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Access = function (config = {}) {
    var defaults = {
        type: 'access',
        title: i18n.t('popup.access.title'),
        message: i18n.t('popup.access.validation_info'),
        listEmpty: i18n.t('popup.access.list_empty'),
        textCancel: i18n.t('popup.access.text_cancel'),
        textSave: i18n.t('popup.access.text_save'),
        disNumpad: true,
        callSave: function (self, store) {
            var oData = {}
            oData.status = store.getters['employee/validateAccess'](self.selectedEl, self.inputVal)
            oData.emp_id = self.selectedEl

            if (oData.status) {
                self.callAfterAccess(oData)
            } else {
                self.showingError = true
                self.messageError = i18n.t('popup.access.error_access')
            }
        }
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const Renew = function (config = {}) {
    var defaults = {
        type: 'renew',
        title: i18n.t('popup.renew.title'),
        message: config.message,
        textSave: i18n.t('popup.renew.text_save')
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const Timeclock = function (config = {}) {
    var defaults = {
        type: 'timeclock',
        title: i18n.t('timeclock.title'),
        textCancel: i18n.t('timeclock.text_cancel'),
        textSave: i18n.t('timeclock.text_save'),
        selectedTab: 'all',
        disNumpad: true,
        callSave: function (self, store) {
            var validation = []
            var employee_id = self.selected
            var employee_pin = self.inputVal

            if (typeof employee_id === 'undefined') {
                validation.push(false)
            }
            if (!Number.isInteger(employee_pin.trim()) && employee_pin.trim().length != 4) {
                validation.push(false)
            }

            if (validation.indexOf(false) == -1) {
                self.showingError = false

                var last_session = store.getters['employee/getEmployee'](employee_id).last_session

                if (last_session.start_date != false && last_session.end_date == false) {
                    var type = 0
                    if (moment().diff(moment(last_session.start_date * 1000), 'hours') >= 12) {
                        type = 1
                    }
                } else {
                    var type = 1
                }

                store
                    .dispatch('employee/doClock', {
                        type: type,
                        employee: employee_id,
                        password: sha256(employee_pin)
                    })
                    .then(function (response) {
                        if (response) {
                            self.$popup.close()
                        } else {
                            self.showingError = true
                            self.messageError = i18n.t('timeclock.error_access')
                        }
                    })
            } else {
                self.showingError = true
                self.messageError = i18n.t('timeclock.error_validation')
            }
        }
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Quantity = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'quantity',
        title: i18n.t('popup.quantity.title'),
        message: i18n.t('popup.quantity.message'),
        callSave: function (self, store) {
            if (self.inputVal == '' || parseInt(self.inputVal) == 0) {
                self.cancel()
            } else {
                if (typeof configCallSave !== 'undefined') {
                    configCallSave(self, store)
                }
                // self.cancel();
            }
        }
    }

    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Numeric = async function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'numeric',
        textCancel: i18n.t('popup.sign.text_cancel'),
        textSave: i18n.t('popup.sign.text_save'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Temperature = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'temperature',
        textCancel: i18n.t('popup.process.text_cancel'),
        textNotNow: i18n.t('popup.sign.text_cancel'),
        textSave: i18n.t('popup.access.text_save'),
        sensorType: 'manual',
        inputVal: '0,0',
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Select = function (config = {}) {
    var configCallSave = config.callSave
    var configCallSaveStart = config.callSaveStart
    var doStart = false

    var defaults = {
        type: 'select',
        title: i18n.t('popup.select.title'),
        listEmpty: i18n.t('popup.select.list_empty'),
        textSave: i18n.t('popup.select.text_save'),
        callSave: function (self, store) {
            configCallSave(self, store)
                .then(function (self, store) {
                    if (doStart) {
                        configCallSaveStart(self, store)
                    } else {
                        return Promise.resolve(self, store)
                    }
                })
                .then(function () {
                    self.$popup.close()
                })
        },
        callSaveStart: function (self, store) {
            doStart = true
            self.callSave(self, store)
        }
    }

    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }
    if (typeof configCallSaveStart !== 'undefined') {
        config.callSaveStart = defaults.callSaveStart
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const SelectDouble = function (config = {}) {
    var configCallSave = config.callSave
    var configCallSaveStart = config.callSaveStart
    var doStart = false

    var defaults = {
        type: 'selectDouble',
        title: i18n.t('popup.select.title'),
        listEmpty: i18n.t('popup.select.list_empty'),
        textSave: i18n.t('popup.select.text_save'),
        callSave: function (self, store) {
            var ctx = self

            configCallSave(ctx, store)
                .then(function (self, store) {
                    if (doStart) {
                        configCallSaveStart(ctx, store)
                    } else {
                        return Promise.resolve(ctx, store)
                    }
                })
                .then(function () {
                    self.$popup.close()
                })
        },
        callSaveStart: function (self, store) {
            doStart = true
            self.callSave(self, store)
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }
    if (typeof configCallSaveStart !== 'undefined') {
        config.callSaveStart = defaults.callSaveStart
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Alert = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'alert',
        mood: 'surprised',
        title: i18n.t('popup.alert.title'),
        textSave: i18n.t('popup.alert.text_save'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const OfflineAlert = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'offlineAlert',
        mood: 'surprised',
        title: i18n.t('popup.alert.title'),
        textSave: i18n.t('popup.alert.text_save'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const Confirm = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'confirm',
        mood: 'surprised',
        title: i18n.t('popup.confirm.title'),
        textCancel: i18n.t('popup.confirm.text_cancel'),
        textSave: i18n.t('popup.confirm.text_save'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }

            if (typeof config.autoClose === 'undefined' || config.autoClose) {
                self.$popup.close()
            }
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const Message = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'message',
        title: i18n.t('popup.confirm.title'),
        textCancel: i18n.t('popup.confirm.text_cancel'),
        textSave: i18n.t('popup.confirm.text_save'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }

            if (typeof config.autoClose === 'undefined' || config.autoClose) {
                self.$popup.close()
            }
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const TableColumn = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'tableColumn',
        mood: 'surprised',
        title: i18n.t('popup.confirm.title'),
        textCancel: i18n.t('popup.confirm.text_cancel'),
        textSave: i18n.t('popup.confirm.text_save'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

// RECONFIRM
const Reconfirm = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'reconfirm',
        mood: 'surprised',
        textCancel: i18n.t('popup.confirm.text_cancel'),
        textSave: i18n.t('popup.confirm.text_save_confirm'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const EmailAlert = function (config = {}) {
    const configCallSave = config.callSave

    const defaults = {
        type: 'emailAlert',
        textCancel: i18n.t('popup.email-alert.text_cancel'),
        textSave: i18n.t('popup.email-alert.text_save_confirm'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }

    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const ConfirmUpload = function (config = {}) {
    var configCallSave = config.callSave
    var configCallSaveStart = config.callSaveStart

    var defaults = {
        type: 'confirmUpload',
        title: i18n.t('popup.confirm.title'),
        textCancel: i18n.t('popup.default.text_close'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        },
        callSaveStart: function (self, store) {
            if (typeof configCallSaveStart !== 'undefined') {
                configCallSaveStart(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }
    if (typeof configCallSaveStart !== 'undefined') {
        config.callSaveStart = defaults.callSaveStart
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Media = function (config = {}) {
    var defaults = {
        type: 'media',
        textCancel: i18n.t('popup.media.text_cancel'),
        logbook: false
    }

    config = {
        ...defaults,
        ...config
    }

    Open.call(this, config)
}
const PreviewSignature = function (config = {}) {
    const defaults = {
        type: 'previewSignature',
        textCancel: i18n.t('popup.media.text_cancel')
    }

    config = {
        ...defaults,
        ...config
    }

    Open.call(this, config)
}
const Library = function (config = {}) {
    var defaults = {
        type: 'library',
        textCancel: i18n.t('popup.default.text_close')
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Calendar = function (config = {}) {
    var defaults = {
        type: 'date'
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const CalendarRange = function (config = {}) {
    var defaults = {
        type: 'dateRange'
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Input = function (config = {}) {
    var defaults = {
        type: 'input'
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Sign = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'sign',
        title: i18n.t('popup.sign.title'),
        textCancel: i18n.t('popup.sign.text_cancel'),
        textSave: i18n.t('popup.sign.text_save'),
        disabledMessage: i18n.t('popup.sign.select_user'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const FreeSign = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'freeSign',
        title: i18n.t('popup.sign.title'),
        textCancel: i18n.t('popup.sign.text_cancel'),
        textSave: i18n.t('popup.sign.text_save'),
        disabledMessage: i18n.t('popup.sign.select_user'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const IssueAlert = function (config = {}) {
    var configCallSave = config.callSave
    var configCallSaveStart = config.callSaveStart
    var doStart = false

    var defaults = {
        type: 'issueAlert',
        title: i18n.t('popup.issue_alert.title'),
        textCancel: i18n.t('popup.issue_alert.text_cancel'),
        textSave: i18n.t('popup.issue_alert.text_save'),
        textSaveStart: i18n.t('popup.issue_alert.text_save_start'),
        showSaveStart: typeof config.showSaveStart !== 'undefined' ? config.showSaveStart : true,
        callSave: function (self, store) {
            configCallSave(self, store)
                .then(function (self, store) {
                    if (doStart) {
                        configCallSaveStart(self, store)
                    } else {
                        return Promise.resolve(self, store)
                    }
                })
                .then(function () {
                    self.$popup.close()
                })
        },
        callSaveStart: function (self, store) {
            doStart = true
            self.callSave(self, store)
        }
    }

    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }
    if (typeof configCallSaveStart !== 'undefined') {
        config.callSaveStart = defaults.callSaveStart
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Process = function (config = {}) {
    var configCallSave = config.callSave
    var configCallCancel = config.callCancel

    var defaults = {
        type: 'process',
        title: i18n.t('popup.process.title'),
        textCancel: i18n.t('popup.process.text_cancel'),
        textSave: i18n.t('popup.process.text_save'),
        textNext: i18n.t('popup.process.text_next'),
        textPrev: i18n.t('popup.process.text_prev'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            // self.$popup.close();
        },
        callCancel: function (self, store) {
            if (typeof configCallCancel !== 'undefined') {
                configCallCancel(self, store)
            }
            self.$popup.close()
        }
    }

    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }
    if (typeof configCallCancel !== 'undefined') {
        config.callCancel = defaults.callCancel
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Postpone = function (config = {}) {
    var defaults = {
        type: 'postpone'
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Justify = function (config = {}) {
    var defaults = {
        type: 'justify'
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Release = function (config = {}) {
    var defaults = {
        type: 'release',
        title: i18n.t('popup.release.title'),
        textSave: i18n.t('popup.release.text_save'),
        textCancel: i18n.t('popup.release.text_cancel')
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Disconnect = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'disconnect',
        mood: 'surprised',
        title: i18n.t('popup.confirm.title'),
        textCancel: i18n.t('popup.delete.text_cancel'),
        textSave: i18n.t('popup.delete.text_save'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Delete = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'delete',
        mood: 'surprised',
        title: i18n.t('popup.confirm.title'),
        textCancel: i18n.t('popup.delete.text_cancel'),
        textSave: i18n.t('popup.delete.text_save'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const Geolocation = function (config = {}) {
    var defaults = {
        type: 'geolocation',
        title: i18n.t('popup.geolocation.title'),
        textSave: i18n.t('audit.error.alert_save'),
        textCancel: i18n.t('popup.geolocation.text_cancel'),
        callSave: function (self, store) {
            localStorage.setItem('askGeolocation', moment())
            // self.gettingLocation = true
            // get position
            navigator.geolocation.getCurrentPosition(
                (pos) => {
                    // self.gettingLocation = false
                    // self.location = pos
                    log(pos.coords.latitude)
                    var location = {
                        latitude: pos.coords.latitude,
                        longitude: pos.coords.longitude
                    }
                    store.commit('setGeoLocation', location)
                },
                (err) => {
                    if (self.closable) {
                        self.$popup.close()
                    }
                    log(err)
                    // self.gettingLocation = false
                    // self.errorStr = err.message
                }
            )
            if (this.closable) {
                this.$popup.close()
            }
        },
        callCancel: function (self, store) {
            if (this.closable) {
                this.$popup.close()
            }
            localStorage.setItem('askGeolocation', moment())
        }
    }

    config = {
        ...defaults,
        ...config
    }

    Open.call(this, config)
}

const Resource = function (config = {}) {
    var defaults = {
        type: 'resource',
        title: i18n.t('popup.resource.title'),
        callCancel: function (self, store) {
            this.$popup.close()
        }
    }

    config = {
        ...defaults,
        ...config
    }

    Open.call(this, config)
}

const ListAudits = function (config = {}) {
    var configCallStart = config.callback
    log(config.level)
    var defaults = {
        type: 'list_audits',
        title: i18n.t('popup.audits.create_title'),
        listEmpty: i18n.t('popup.audits.list_empty'),
        textCancel: i18n.t('popup.login.text_cancel'),
        textSave: i18n.t('popup.audits.text_save'),
        textSaveSupervisor: i18n.t('popup.audits.text_save_supervisor'),
        level: config.level,
        callSave: function (self, store) {
            if (!self.selected) {
                self.showingError = true
                self.messageError = i18n.t('popup.audits.error_validation')
            } else {
                configCallStart(self, store)
            }
        }
    }
    //   console.log(typeof config.list, config.list, Object.keys(config.list))
    if (typeof config.list !== 'undefined') {
        var listAux = []
        for (var k in config.list) {
            listAux.push(config.list[k])
        }

        listAux.sort(function (a, b) {
            if (a.name < b.name) return -1
            return 1
        })

        config.list = listAux
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const Ticket = function (config = {}) {
    let defaults = {
        type: 'ticket',
        title: i18n.t('tickets.add_ticket'),
        textSave: i18n.t('tickets.send'),
        textCancel: i18n.t('tickets.cancel')
    }

    config = {
        ...defaults,
        ...config
    }

    Open.call(this, config)
}

const DateTime = function (config = {}) {
    const defaults = {
        type: 'dateTime',
        title: i18n.t('label.print.datetime_title'),
        textSave: i18n.t('popup.default.text_save'),
        textCancel: i18n.t('popup.default.text_close'),
        date: config.date,
        time: config.time,
        only_past: typeof config.only_past !== 'undefined' ? config.only_past : false
    }

    config = {
        ...defaults,
        ...config
    }

    Open.call(this, config)
}

const ItemSettings = function (config = {}) {
    const defaults = {
        type: 'itemSettings',
        title: i18n.t('popup.item_settings.title')
    }

    config = {
        ...defaults,
        ...config
    }

    Open.call(this, config)
}

const ProductInfo = function (config = {}) {
    var defaults = {
        type: 'productInfo',
        productName: config.productName,
        productContainer: config.container,
        productIcon: config.icon,
        projectedProduction: config.projectedProduction,
        realProduction: config.realProduction,
        remainingProduction: config.remainingProduction,
        projectedProductionDifference: config.projectedProductionDifference,
        realProductionDifference: config.ProductionDifference
    };

    config = {
        ...defaults,
        ...config
    };

    Open.call(this, config);
};

const Projection = function (config = {}) {
    let defaults = {
        type: 'projection',
        title: i18n.t('popup.product_info.projection.title'),
        description: i18n.t('popup.product_info.projection.description'),
    }

    config = {
        ...defaults,
        ...config
    }

    Open.call(this, config)
}

const ProjectionReturn = function (config = {}) {
    let defaults = {
        type: 'projectionReturn',
        title: i18n.t('popup.product_info.projection.title'),
        description: i18n.t('popup.product_info.projection.description'),
        textClose: i18n.t('popup.product_info.projection.close')
    }

    config = {
        ...defaults,
        ...config
    }

    Open.call(this, config)
}

export default {
    install(Vue) {
        Vue.prototype.$popup = Init.bind(Vue)
        Vue.prototype.$popup.open = Open.bind(Vue)
        Vue.prototype.$popup.close = Close.bind(Vue)
        Vue.prototype.$popup.login = Login.bind(Vue)
        Vue.prototype.$popup.access = Access.bind(Vue)
        Vue.prototype.$popup.timeclock = Timeclock.bind(Vue)
        Vue.prototype.$popup.quantity = Quantity.bind(Vue)
        Vue.prototype.$popup.numeric = Numeric.bind(Vue)
        Vue.prototype.$popup.temperature = Temperature.bind(Vue)
        Vue.prototype.$popup.select = Select.bind(Vue)
        Vue.prototype.$popup.selectDouble = SelectDouble.bind(Vue)
        Vue.prototype.$popup.alert = Alert.bind(Vue)
        Vue.prototype.$popup.confirm = Confirm.bind(Vue)
        Vue.prototype.$popup.tableColumn = TableColumn.bind(Vue)
        Vue.prototype.$popup.reconfirm = Reconfirm.bind(Vue)
        Vue.prototype.$popup.confirmUpload = ConfirmUpload.bind(Vue)
        Vue.prototype.$popup.media = Media.bind(Vue)
        Vue.prototype.$popup.previewSignature = PreviewSignature.bind(Vue)
        Vue.prototype.$popup.library = Library.bind(Vue)
        Vue.prototype.$popup.date = Calendar.bind(Vue)
        Vue.prototype.$popup.dateRange = CalendarRange.bind(Vue)
        Vue.prototype.$popup.input = Input.bind(Vue)
        Vue.prototype.$popup.sign = Sign.bind(Vue)
        Vue.prototype.$popup.freeSign = FreeSign.bind(Vue)
        Vue.prototype.$popup.issueAlert = IssueAlert.bind(Vue)
        Vue.prototype.$popup.process = Process.bind(Vue)
        Vue.prototype.$popup.postpone = Postpone.bind(Vue)
        Vue.prototype.$popup.justify = Justify.bind(Vue)
        Vue.prototype.$popup.release = Release.bind(Vue)
        Vue.prototype.$popup.disconnect = Disconnect.bind(Vue)
        Vue.prototype.$popup.delete = Delete.bind(Vue)
        Vue.prototype.$popup.geolocation = Geolocation.bind(Vue)
        Vue.prototype.$popup.resource = Resource.bind(Vue)
        Vue.prototype.$popup.listAudits = ListAudits.bind(Vue)
        Vue.prototype.$popup.ticket = Ticket.bind(Vue)
        Vue.prototype.$popup.dateTime = DateTime.bind(Vue)
        Vue.prototype.$popup.emailAlert = EmailAlert.bind(Vue)
        Vue.prototype.$popup.renew = Renew.bind(Vue)
        Vue.prototype.$popup.offlineAlert = OfflineAlert.bind(Vue)
        Vue.prototype.$popup.itemSettings = ItemSettings.bind(Vue)
        Vue.prototype.$popup.productInfo = ProductInfo.bind(Vue)
        Vue.prototype.$popup.projection = Projection.bind(Vue)
        Vue.prototype.$popup.projectionReturn = ProjectionReturn.bind(Vue)
        Vue.prototype.$popup.message = Message.bind(Vue)
    }
}
