import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { Api } from '@/core/api.js'

import es from './es.json'
import en from './en.json'
import pt from './pt.json'
import de from './de.json'
import fr from './fr.json'
import it from './it.json'
import init from './init.json'
const api = new Api()

const IS_APP = process.env.VUE_APP_IS_APP === 'TRUE'
const defaultLocale = localStorage.language ? localStorage.language : 'init'

moment.updateLocale('es', {
    calendar: {
        lastDay: '[Ayer]',
        sameDay: '[Hoy]',
        nextDay: '[Mañana]',
        lastWeek: '[El pasado] dddd',
        nextWeek: '[La próxima semana]',
        sameElse: 'dddd, DD [de] MMMM [del] YYYY'
    }
})
moment.updateLocale('en', {
    calendar: {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: '[Last] dddd',
        nextWeek: '[The next week]',
        sameElse: 'dddd, DD [of] MMMM [of] YYYY'
    }
})
moment.updateLocale('pt', {
    calendar: {
        lastDay: '[Ontem]',
        sameDay: '[Hoje]',
        nextDay: '[Manhã]',
        lastWeek: 'dddd [passada]',
        nextWeek: '[A próxima semana]',
        sameElse: 'dddd, DD [de] MMMM [de] YYYY'
    }
})
moment.updateLocale('de', {
    calendar: {
        lastDay: '[Gestern]',
        sameDay: '[Heute]',
        nextDay: '[Morgen]',
        lastWeek: '[Letzter] dddd',
        nextWeek: '[Nächste Woche]',
        sameElse: 'dddd, DD. MMMM YYYY'
    }
})
moment.updateLocale('fr', {
    calendar: {
        lastDay: '[Hier]',
        sameDay: "[Aujourd'hui]",
        nextDay: '[Demain]',
        lastWeek: '[La semaine dernière] dddd',
        nextWeek: '[La semaine prochaine]',
        sameElse: 'dddd, DD [de] MMMM [de] YYYY'
    }
})
moment.locale(defaultLocale)

Vue.use(VueI18n)

let languages = {
    init: init
}
let loadedLanguages = ['init']

if (IS_APP) {
    loadedLanguages.push('es')
    loadedLanguages.push('pt')
    loadedLanguages.push('en')
    loadedLanguages.push('de')
    loadedLanguages.push('fr')
    loadedLanguages.push('it')

    languages.es = es
    languages.pt = pt
    languages.en = en
    languages.de = de
    languages.fr = fr
    languages.it = it
}
const i18n = new VueI18n({
    //   locale: defaultLocale,
    locale: 'init',
    fallbackLocale: 'init',
    messages: Object.assign(languages),
    silentTranslationWarn: true
})

i18n.loadLanguageAsync = function (lang) {
    if (i18n.locale !== lang) {
        if (!loadedLanguages.includes(lang) || fnCheckConnection()) {
            return api
                .language(lang)
                .then((response) => {
                    log(typeof response)
                    if (typeof response === 'string') {
                        response = JSON.parse(response)
                    }
                    i18n.setLocaleMessage(lang, response)
                    moment.locale(lang)
                    loadedLanguages.push(lang)
                    return setI18nLanguage(lang)
                })
                .catch((error) => {
                    logError(error)
                    return false
                })
        }
        return Promise.resolve(setI18nLanguage(lang))
    } else if (fnCheckConnection() && lang == 'init') {
        // Download es.json lang for default if it is online
        lang = 'es'
        moment.locale(lang)
        return api.language(lang).then((response) => {
            i18n.setLocaleMessage(lang, response)
            loadedLanguages.push(lang)
            return setI18nLanguage(lang)
        })
    }
    return Promise.resolve(lang)
}

i18n.loadLanguageAsync(defaultLocale)

export default i18n

function setI18nLanguage(lang) {
    log('setI18nLanguage', lang)
    i18n.locale = lang
    moment.locale(lang)
    document.querySelector('html').setAttribute('lang', lang)
    log(i18n.messages)
    return lang
}
