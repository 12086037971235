<template>
    <div class="tooltip-container slideUp" :class="[customClass, type, $mq]">
        <div class="andy-mood slideRight"></div>
        <div class="andy-talk" :class="type" @click="actionTooltip">
            <vue-typed-js :strings="[message]" :loop="false" :typeSpeed="2" :startDelay="0" :showCursor="false">
                <span class="preview hide-scrollbar typing"></span>
            </vue-typed-js>
            <div v-if="actionType == ''" class="close fadeInFast"></div>
            <div v-else class="button-undo">{{ textButton }}</div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import VueTypedJs from 'vue-typed-js'
Vue.use(VueTypedJs)

export default {
    data() {
        return {
            show: false,
            talk: false,
            closeable: true,
            type: '',
            duration: 3000,
            message: '',
            action: function () {},
            customClass: '',
            actionType: '',
            textButton: ''
        }
    },
    computed: {},
    methods: {
        actionTooltip() {
            this.action(this)
        }
    },
    created() {
        // playSound('empty.wav')
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.tooltip-container {
    @include interaction();
    @include display-flex();
    @include flex-wrap();
    @include justify-content();
    @include align-items(center);
    width: 100%;
    height: auto;
    position: fixed;
    left: 0;
    bottom: 10px;
    z-index: $z-snackbar-default;
    pointer-events: none;

    .andy-mood {
        @include background($image: img('alex_carita_02_right.svg'), $size: contain);
        width: 60px;
        height: 60px;
        display: inline-block;
        margin-right: 6px;
        pointer-events: initial;
    }

    .andy-talk {
        @include background($color: $inactive-t90, $size: 30px);
        @include default-box-shadow();
        @include border-radius(4px);
        @include align-items();
        @include font-size(ml);
        @include flex-direction(row);
        color: $inactive-s70;
        font-family: $conden;
        min-width: $menu-top-height;
        max-width: calc(100% - 120px);
        width: auto;
        min-height: 40px;
        height: auto;
        padding: 10px 12px;
        pointer-events: initial;
        @include display-flex();
        box-shadow: none;

        span {
            text-align: left;
            font-family: $text-light;
            @include font-size(sm);
        }

        & b,
        & a {
            font-family: $text-bold;
            @include font-size(sm);
        }

        & a {
            text-decoration: underline;
        }
    }

    .typed-element {
        width: calc(100% - 40px);
        padding-right: 25px;
    }

    .close {
        width: 40px;
        height: 40px;
        @include background($image: img('tick_ffffff.svg'), $size: 25px);
        display: flex;
        @include border-radius(4px);

        &:hover {
            opacity: 0.5;
        }
    }

    &.info {
        .andy-mood {
            background-image: img('alex_carita_10.svg');
        }
        .andy-talk {
            background-color: $info-t30;
            color: $info-s70;
        }
        .close {
            background-color: $info-s20;
        }
    }
    &.success {
        .andy-mood {
            background-image: img('alex_carita_07.svg');
        }
        .andy-talk {
            background-color: $success;
            color: $success-text;
        }
        .close {
            background-color: $success-dark;
        }
    }
    &.progress {
        .andy-mood {
            background-image: img('alex_carita_07.svg');
        }
        .andy-talk {
            background-color: $progress-t30;
            color: $progress-s70;
        }
        .close {
            background-color: $progress-s20;
        }
    }
    &.warning {
        .andy-mood {
            background-image: img('alex_carita_05.svg');
        }
        .andy-talk {
            background-color: $warning-t30;
            color: $warning-s70;
        }
        .close {
            background-color: $warning-s20;
        }
    }
    &.error {
        .andy-mood {
            background-image: img('alex_carita_04.svg');
        }
        .andy-talk {
            background-color: #bd1f1e;
            color: $error-s70;
            span {
                color: $color-white;

                b {
                    color: inherit;
                }
            }
        }
        .close {
            background-color: #7a1313;
        }
    }
    &.login {
        .andy-mood {
        }
        .andy-talk {
        }
    }
    &.offline {
        .andy-mood {
            background-image: img('alex_carita_05.svg');
        }
        .andy-talk {
        }
    }
    &.important {
        .andy-mood {
        }
        .andy-talk {
        }
    }
    &.loading {
        .andy-mood {
        }
        .andy-talk {
        }
    }
}

.delete-timer-button {
    color: green;

    .button-undo {
        background-color: $color-white;
        width: 120px;
        height: 36px;
        border-radius: 4px;
        padding: 8px;
        font-family: $text-bold;
    }

    &.error {
        font-family: $text-bold !important;
        .andy-mood {
            background-image: img('alex_carita_04.svg');
        }
        .andy-talk {
            background-color: $color-error-500;
            color: $color-white;
            span {
                color: $color-white;
                font-family: $text-bold;
                b {
                    color: inherit;
                }
            }
        }
        .close {
            background-color: black;
        }
    }
}
// Coloca un piquito, como si fuera un dialogo (se necesita ponerle un border al padre)
// .andy-talk:after {
//     content: '';
//     position: absolute;
//     left: 0;
//     top: 50%;
//     width: 0;
//     height: 0;
//     border: 6px solid transparent;
//     border-right-color: inherit;
//     border-left: 0;
//     // border-bottom: 0;
//     margin-top: -6px;
//     margin-left: -6px;
// }
</style>

// PORTRAIT STYLES
<style lang="scss">
.tooltip-container.portrait {
    z-index: 18000;
    bottom: 5px;
    @include align-items(center);

    .andy-mood {
        position: absolute;
        top: -45px;
        left: 5px;
    }

    .andy-talk {
        @include display-flex();
        @include flex-wrap();
        @include justify-content();
        @include align-items(center);
        @include font-size(s);
        @include flex-direction(row);
        width: calc(100% - 10px);
        max-width: calc(100% - 10px);
        text-align: center;
        min-height: 55px;
        box-shadow: none;

        .typed-element {
            padding-right: 10px;
        }

        span {
            text-align: left;
            line-height: 16px;
            @include font-size(xs);
        }

        & b,
        & a {
            display: contents;
            font-family: $text-bold;
            @include font-size(xs);
        }

        & a {
            text-decoration: underline;
        }
    }
}
</style>
